<template >
  <div class="containter">
    <div class="headSearchLine">
      <img v-if="searchIconVisible" :src="searchIcon" class="searchIcon" />
      <!-- <div v-if="!searchIconVisible" class="searchIconRight" @click="searchChange">
        <img :src="searchIcon" style="width:100%;height:100%;" />
      </div>-->

      <van-field
        v-model="searchInput"
        @focus="getInputChange"
        @blur="blurChange"
        class="searchInput"
        :placeholder="placehold"
        input-align="center"
        @input="searchChange"
      />
    </div>
    <div class="radioButtonLine">
      <div class="radioButtonItem" @click="changeList(0)">
        <div class="radioButtonFont" :style="selectRow===0?'color:rgb(252,0,36)':''">进行中</div>
        <div
          class="radioButtonBottomLine"
          :style="selectRow===0?'background-color:rgb(252,0,36)':''"
        ></div>
      </div>
      <div class="radioButtonItem" @click="changeList(1)">
        <div class="radioButtonFont" :style="selectRow===1?'color:rgb(252,0,36)':''">已结束</div>
        <div
          class="radioButtonBottomLine"
          :style="selectRow===1?'background-color:rgb(252,0,36)':''"
        ></div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div v-if="selectRow===0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          :offset="70"
          error-text="请求失败，请重新加载"
          @load="onLoad"
        >
          <div class="listCell" v-for="item in list" :key="item.id">
            <div class="listItem">
              <img
                v-if="item.signStatus===1&&selectRow===0"
                :src="signInImg"
                class="listItemSignInImg"
              />
              <img v-if="selectRow===1" :src="endActivity" class="listItemSignInImg" />
              <div class="listItemTitleLine">
                <div class="avtorArea">
                  <!-- <img :src="item.img" style="width:100%;height:100%;" /> -->
                  <van-image fit="cover" :src="item.img" style="width:100%;height:100%;">
                    <template v-slot:error>
                      <img :src="errorPicAvatar" style="width:100%;height:100%;" />
                    </template>
                  </van-image>
                </div>
                <div class="listItemTitleLineContent">
                  <div class="listItemTitleLineContent1">{{ item.deptName}}</div>
                  <div class="listItemTitleLineContent2">{{ item.activityStartTime}}</div>
                </div>
              </div>
              <div class="baseLine"></div>
              <div class="mainTitleLine">
                <span class="marginLeft10">{{item.activityName}}</span>
              </div>
              <div class="mainImgArea" @click="gotoDetail(item.id)">
                <!-- <img :src="item.websiteBanner" class="mainImg" /> -->
                <van-image fit="cover" :src="item.websiteBanner" class="mainImg">
                  <template v-slot:error>
                    <img :src="errorImg" style="width:100%;height:100%;" />
                  </template>
                </van-image>
              </div>
              <div class="mainBottomLine">
                <div class="leftMainArea">
                  <img
                    v-if="!item.praiseStatus"
                    @click="getPraisePost(item)"
                    :src="greyLove"
                    class="leftMainAreaImg"
                  />
                  <img v-else :src="redLove" class="leftMainAreaImg" />
                  <div class="leftMainAreaFont">{{item.praiseCount}}</div>
                  <img :src="eyeIcon" class="leftMainEyeImg" />
                  <div class="leftMainAreaFont">{{item.readCount===-1?0:item.readCount}}</div>
                </div>
                <div class="rightMainArea">
                  <div class="leftMainAreaFont2">报名人数：</div>
                  <span class="leftMainAreaFontNumNow">{{item.enrollCount}}</span>
                  <span class="leftMainAreaFontNum">/</span>
                  <span class="leftMainAreaFontNum marginRight10">{{item.activityNum}}</span>
                  <div
                    v-if="item.enrollStatus===0&&item.enrollCount!==item.activityNum&&selectRow===0"
                    class="baomingButton backRed"
                    @click="getBaomingPost(item)"
                  >立即报名</div>
                  <div
                    v-if="item.enrollStatus>0&&selectRow===0"
                    class="baomingButton backWhite"
                  >已报名</div>
                  <div
                    v-if="item.enrollStatus===0&&item.enrollCount===item.activityNum&&selectRow===0"
                    class="baomingButton backGrey"
                  >人数已满</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>

      <div v-else>
        <van-list
          v-model="loading1"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          :offset="70"
          error-text="请求失败，请重新加载"
          @load="onLoad"
        >
          <div class="listCell" v-for="item in list1" :key="item.id">
            <div class="listItem">
              <img
                v-if="item.signStatus===1&&selectRow===0"
                :src="signInImg"
                class="listItemSignInImg"
              />
              <img v-if="selectRow===1" :src="endActivity" class="listItemSignInImg" />
              <div class="listItemTitleLine">
                <div class="avtorArea">
                  <!-- <img :src="item.img" style="width:100%;height:100%;" /> -->
                  <van-image fit="cover" :src="item.img" style="width:100%;height:100%;">
                    <template v-slot:error>
                      <img :src="errorPicAvatar" style="width:100%;height:100%;" />
                    </template>
                  </van-image>
                </div>
                <div class="listItemTitleLineContent">
                  <div class="listItemTitleLineContent1">{{ item.deptName}}</div>
                  <div class="listItemTitleLineContent2">{{ item.activityStartTime}}</div>
                </div>
              </div>
              <div class="baseLine"></div>
              <div class="mainTitleLine">
                <span class="marginLeft10">{{item.activityName}}</span>
              </div>
              <div class="mainImgArea" @click="gotoDetail(item.id)">
                <!-- <img :src="item.websiteBanner" class="mainImg" /> -->
                <van-image fit="cover" :src="item.websiteBanner" class="mainImg">
                  <template v-slot:error>
                    <img :src="errorImg" style="width:100%;height:100%;" />
                  </template>
                </van-image>
              </div>
              <div class="mainBottomLine">
                <div class="leftMainArea">
                  <img
                    v-if="!item.praiseStatus"
                    @click="getPraisePost(item)"
                    :src="greyLove"
                    class="leftMainAreaImg"
                  />
                  <img v-else :src="redLove" class="leftMainAreaImg" />
                  <div class="leftMainAreaFont">{{item.praiseCount}}</div>
                  <img :src="eyeIcon" class="leftMainEyeImg" />
                  <div class="leftMainAreaFont">{{item.readCount===-1?0:item.readCount}}</div>
                </div>
                <div class="rightMainArea">
                  <div class="leftMainAreaFont2">报名人数：</div>
                  <span class="leftMainAreaFontNumNow">{{item.enrollCount}}</span>
                  <span class="leftMainAreaFontNum">/</span>
                  <span class="leftMainAreaFontNum marginRight10">{{item.activityNum}}</span>
                  <div
                    v-if="item.enrollStatus===0&&item.enrollCount!==item.activityNum&&selectRow===0"
                    class="baomingButton backRed"
                    @click="getBaomingPost(item)"
                  >立即报名</div>
                  <div
                    v-if="item.enrollStatus===1&&selectRow===0"
                    class="baomingButton backWhite"
                  >已报名</div>
                  <div
                    v-if="item.enrollStatus===0&&item.enrollCount===item.activityNum&&selectRow===0"
                    class="baomingButton backGrey"
                  >人数已满</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { getActivityList, praisePost, baomingPost } from '../../api/activity';
import * as dd from 'dingtalk-jsapi';
export default {
  name: 'ActivityList',

  data() {
    return {
      searchIconVisible: true,
      searchIcon: require('./imgs/searchIcon.png'),
      redLove: require('./imgs/redHeart.png'),
      greyLove: require('./imgs/greyHeart.png'),
      eyeIcon: require('./imgs/eyeIcon.png'),
      signInImg: require('./imgs/signIn.png'),
      endActivity: require('./imgs/endingActivity.png'),
      errorImg: require('../../assets/img/list.png'),
      errorPicAvatar: require('../../assets/img/list-compre.png'),
      searchInput: '',
      placehold: '活动名称',
      selectRow: 0,
      param: {
        page: 0,
        size: 5,
        total: 20
      },
      param1: {
        page: 0,
        size: 5,
        total: 20
      },
      loading: false,
      loading1: false,
      finished: false,
      refreshing: false,
      error: false,
      fakeList: [
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: true,
          enrollStatus: false,
          zan: 23,
          ownZan: true,
          eyeNum: 60,
          img: '',
          totalNum: 25,
          nowNum: 21
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: false,
          enrollStatus: true,
          zan: 23,
          ownZan: false,
          eyeNum: 60,
          img: '',
          totalNum: 25,
          nowNum: 21
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: false,
          enrollStatus: true,
          zan: 23,
          ownZan: true,
          eyeNum: 60,
          img: '',
          totalNum: 25,
          nowNum: 21
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: true,
          enrollStatus: false,
          zan: 23,
          ownZan: true,
          eyeNum: 60,
          img: '',
          totalNum: 25,
          nowNum: 21
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: true,
          enrollStatus: false,
          zan: 23,
          ownZan: false,
          eyeNum: 60,
          img: '',
          totalNum: 25,
          nowNum: 21
        }
      ],
      list: [],
      list1: [],
      getRecordStatus: false,
      getRecordLoading1: false
    };
  },
  watch: {},
  created() {
    // 让活动详情返回的列表和进去时一样
    dd.biz.navigation.setTitle({
      title: '活动', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
  },
  // 如果从首页返回就退出
  beforeRouteEnter(to, from, next) {
    let selectRow = 0;
    if (from.path === '/activityDetail') {
      selectRow = Number(localStorage.getItem('avtivityListSelectRow'));
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      console.log(vm);
      vm.selectRow = selectRow; //当前组件的实例
      vm.getRecords();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    const selectRow = this.selectRow;
    localStorage.setItem('avtivityListSelectRow', selectRow);
    next();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      this.getRecordStatus = true;
      const this_ = this;
      const param = this.param;
      param.page += 1;
      const searchParam = { ...param };
      searchParam.status = this.selectRow;
      // 调接口

      getActivityList({
        current: searchParam.page,
        size: 5,
        status: searchParam.status,
        activityName: this_.searchInput || ''
      })
        .then(res => {
          if (res.success) {
            const activityList = res.data.records;
            // 数据全部加载完成
            if (activityList.length === 0) {
              this_.finished = true;
            }
            let list = this_.list;
            // list = list.concat(activityList);
            list = [...list, ...activityList];
            this_.list = JSON.parse(JSON.stringify(list));
            console.log(this_.list);
            param.total = res.data.total;
            this_.param = param;
            // 加载状态结束
            this_.loading = false;
            this_.refreshing = false;
            this_.error = false;
          } else {
            // 加载状态结束
            // this_.loading = false;
            this_.error = true;
            console.log(res.msg);
          }
          this_.getRecordStatus = false;
        })
        .catch(err => {
          // 加载状态结束
          // this_.loading = false;
          this_.error = true;
          this_.getRecordStatus = false;
          console.log(err);
        });
    },
    getRecords1() {
      this.getRecordLoading1 = true;
      const this_ = this;
      const param1 = this.param1;
      param1.page += 1;
      const searchParam = { ...param1 };
      searchParam.status = this.selectRow;
      // 调接口

      getActivityList({
        current: searchParam.page,
        size: 5,
        status: searchParam.status,
        activityName: this_.searchInput || ''
      })
        .then(res => {
          if (res.success) {
            const activityList = res.data.records;
            // 数据全部加载完成
            if (activityList.length === 0) {
              this_.finished = true;
            }
            let list1 = this_.list1;
            console.log(this_.list1);
            console.log(activityList);
            // list = list.concat(activityList);
            list1 = [...list1, ...activityList];
            this_.list1 = JSON.parse(JSON.stringify(list1));
            // console.log(this_.list1);
            param1.total = res.data.total;
            this_.param1 = param1;
            // 加载状态结束
            this_.loading1 = false;
            this_.refreshing = false;
            this_.error = false;
          } else {
            // 加载状态结束
            // this_.loading = false;
            this_.error = true;
            console.log(res.msg);
          }
          this_.getRecordLoading1 = false;
        })
        .catch(err => {
          // 加载状态结束
          // this_.loading = false;
          this_.error = true;
          this_.getRecordLoading1 = false;
          console.log(err);
        });
    },
    //加载更多数据
    onLoad() {
      const selectRow = this.selectRow;
      if (selectRow === 0) {
        if (!this.getRecordStatus) {
          console.log('加载自动刷新');
          const timer = setTimeout(() => {
            this.getRecords();
            this.loading = false;
            clearTimeout(timer);
          }, 500);
        }
      } else {
        if (!this.getRecordLoading1) {
          console.log('加载自动刷新1');
          console.log(this.getRecordLoading1);
          const timer = setTimeout(() => {
            this.getRecords1();
            this.loading1 = false;
            clearTimeout(timer);
          }, 500);
        }
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];
      this.list1 = [];
      this.param.page = 0;
      this.param1.page = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loading1 = true;
      console.log('下拉刷新');
      this.onLoad();
    },
    // 查询的改变
    changeList(num) {
      this.selectRow = num;
      this.param.page = 0;
      this.param1.page = 0;
      this.finished = false;
      this.list = [];
      this.list1 = [];
      this.loading = true;
      this.loading1 = true;
      console.log('转换刷新');
      this.onLoad();
    },
    // 输入框改变
    searchChange() {
      console.log('fdfdsfsdsd');
      this.param.page = 0;
      this.param1.page = 0;
      this.finished = false;
      this.list = [];
      this.list1 = [];
      this.onLoad();
    },
    // 点赞
    getPraisePost(item) {
      praisePost({
        addPraise: true,
        id: item.id,
        type: 'ACTIVITY_TYPE'
      })
        .then(res => {
          if (res.success) {
            item.praiseStatus = true;
            item.praiseCount += 1;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 报名
    getBaomingPost(item) {
      baomingPost({
        enrolled: true,
        id: item.id
      })
        .then(res => {
          if (res.success) {
            item.enrollStatus = 1;
            item.enrollCount += 1;
            Notify({ type: 'success', message: '报名成功' });
          } else {
            Notify({ type: 'warning', message: res.msg });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    gotoDetail(id) {
      const selectRow = this.selectRow;
      this.$router.push({
        name: 'activityDetail',
        query: { id: id, selectRow: selectRow }
      });
    },
    getInputChange() {
      this.searchIconVisible = false;
    },
    blurChange() {
      this.searchIconVisible = true;
      // this.searchInput = '';
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-icon {
  display: flex;
  align-items: center;
}
/deep/ .van-cell {
  padding: 0px;
}
/deep/ .ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
  white-space: pre;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headSearchLine {
  width: 100%;
  height: 40 * $px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.searchInput {
  width: 355 * $px;
  height: 29 * $px;
  line-height: 29 * $px;
  border-radius: 14.5 * $px;
  background-color: #f5f5f5;
  padding: 0px;
}
.searchIcon {
  width: 12 * $px;
  height: 12 * $px;
  position: absolute;
  top: 13.5 * $px;
  left: 146 * $px;
  z-index: 1;
  z-index: 100;
}
.searchIconRight {
  width: 16 * $px;
  height: 16 * $px;
  position: absolute;
  top: 11.5 * $px;
  right: 30 * $px;
  z-index: 100;
}
.radioButtonLine {
  height: 34 * $px;
  width: 100%;
  background-color: #ffffff;
}
.radioButtonItem {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.radioButtonFont {
  font-size: 14 * $px;
  color: #555555;
  margin-bottom: 7 * $px;
}
.radioButtonBottomLine {
  width: 30 * $px;
  height: 2 * $px;
  border-radius: 0.5 * $px;
  background-color: #ffffff;
}
.listItem {
  width: 100%;
  height: 100%;
  position: relative;
}
.listCell {
  margin: 10 * $px 10 * $px 0 10 * $px;
  width: 355 * $px;
  height: 273.5 * $px;
  background-color: #ffffff;
}
.listItemTitleLine {
  width: 100%;
  height: 60 * $px;
}
.avtorArea {
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 20 * $px;
  overflow: hidden;
  background-color: #dddddd;
  margin: 10 * $px;
  float: left;
}
.listItemTitleLineContent {
  width: 280 * $px;
  height: 100%;
  float: left;
}
.listItemTitleLineContent1 {
  font-size: 14 * $px;
  color: #555555;
  margin: 12 * $px 10 * $px 0 * $px 10 * $px;
}
.listItemTitleLineContent2 {
  font-size: 11 * $px;
  color: #999999;
  margin-left: 10 * $px;
}
.baseLine {
  width: 335 * $px;
  height: 0.5 * $px;
  margin-left: 10 * $px;
  background-color: #eeeeee;
}
.mainTitleLine {
  width: 100%;
  height: 39 * $px;
  line-height: 39 * $px;
  color: #222222;
  font-size: 16 * $px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.mainImgArea {
  width: 100%;
  height: 130 * $px;
  display: flex;
  justify-content: center;
}
.mainImg {
  width: 335 * $px;
  height: 100%;
}
.mainBottomLine {
  width: 100%;
  height: 44 * $px;
}
.leftMainArea {
  width: 35%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rightMainArea {
  width: 65%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftMainAreaImg {
  width: 16 * $px;
  height: 14.5 * $px;
  margin-left: 12 * $px;
}
.leftMainAreaFont {
  width: 40 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainAreaFont2 {
  width: 62 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainEyeImg {
  width: 16 * $px;
  height: 12 * $px;
}
.leftMainAreaFontNum {
  font-size: 12 * $px;
  color: #555555;
}
.leftMainAreaFontNumNow {
  color: #2797ff;
  font-size: 16 * $px;
}
.baomingButton {
  width: 70 * $px;
  height: 24 * $px;
  border-radius: 12 * $px;
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12 * $px;
  margin-right: 10 * $px;
  margin-left: 10 * $px;
}
.backRed {
  background-color: #ff192f;
  color: #ffffff;
}
.backWhite {
  background-color: #ffffff;
  color: #ff192f;
}
.backGrey {
  color: #999999;
  border: #999999 1 * $px solid;
  box-sizing: border-box;
}
.listItemSignInImg {
  width: 70 * $px;
  height: 70 * $px;
  position: absolute;
  top: 15 * $px;
  right: 15 * $px;
}
.marginRight10 {
  margin-right: 10 * $px;
}
</style>



